<template>
  <b-card title="Controllo vincitore">
    <div v-if="finish && user.length > 0">
      <b-alert
        variant="warning"
        :show="isEnded(user[0].date_deadline)"
      >
        <h4 class="alert-heading">
          ATTENZIONE IL BUONO E' SCADUTO
        </h4>
      </b-alert>
      <b-alert
        variant="warning"
        :show="user[0].date_used != null"
      >
        <h4 class="alert-heading">
          ATTENZIONE IL BUONO E' GIA' STATO UTILIZZATO
        </h4>
      </b-alert>
      <b-card-text>
        <b>Nome e cognome:</b> {{ user[0].fullname }}
      </b-card-text>
      <b-card-text>
        <b>Email:</b> {{ user[0].email }}
      </b-card-text>
      <b-card-text>
        <b>CAP:</b> {{ user[0].cap }}
      </b-card-text>
      <b-card-text>
        <b>Data di nascita:</b> {{ getDate(user[0].birthdate) }}
      </b-card-text>
      <b-card-text>
        <b>Data vincita:</b> {{ getDate(user[0].date_win) }}
      </b-card-text>
      <b-card-text>
        <b>Data scadenza:</b> {{ getDate(user[0].date_deadline) }}
      </b-card-text>
      <b-card-text>
        <b>Data utilizzo:</b> <span v-html="getDate(user[0].date_used)"></span>
      </b-card-text>
      <b-card-text>
        <b>Codice vincita:</b> #{{ user[0].assigned_number }}-{{ user[0].cap }}
      </b-card-text>
      <b-button
        v-if="user[0].date_used == null && !isEnded(user[0].date_deadline)"
        variant="gradient-success"
        @click="setUsed(user[0].email)"
      >
        CONTRASSEGNA COME UTILIZZATO
      </b-button>
    </div>
    <div
      v-else
      class="text-danger"
    >
      Nessuna corrispondenza trovata.
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BAlert, BButton,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BAlert,
    BButton,
  },
  data() {
    return {
      user: [],
      finish: false,
    }
  },
  mounted() {
    this.checkUser()
  },
  methods: {
    getPrize(prize) {
      let x = ''
      switch (prize) {
        case 1:
          x = '10% DI SCONTO'
          break
        case 2:
          x = '15% DI SCONTO'
          break
        case 3:
          x = 'DESSERT'
          break
        case 4:
          x = 'PROSECCO'
          break
        case 5:
          x = 'BUONO 2X1'
          break
        default:
          x = 'ERRORE'
      }
      return x
    },
    isEnded(date) {
      const x = new Date(date)
      const y = new Date()
      return x <= y
    },
    getDate(date) {
      if (date !== null && date !== undefined) {
        if (date.trim() !== '') {
          return window.moment(date).format('DD/MM/YYYY')
        }
      }
      return '<b class="text-success"> NON ANCORA UTILIZZATO </b>'
    },
    setUsed(email) {
      this.$http.post(`/slotmachine/check/${email}`).then(() => {
        this.user = []
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Buono contrassegnato come utilizzato.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.checkUser()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel contrassegnare il buono.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
    checkUser() {
      const red = atob(decodeURIComponent(window.location.search.substr(6)))
      this.$http.get(`/slotmachine/check/${red}`).then(response => {
        this.user = response.data
        this.finish = true
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel recupero dell\'utente.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
  },
}
</script>

<style>

</style>
